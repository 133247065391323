import "./MyCareersView.css";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import BadgeIcon from '@mui/icons-material/Badge';
import LinkIcon from '@mui/icons-material/Link';
import TextField from "@mui/material/TextField";

import Loader from "components/misc/Loader/Loader";
import UserCareerExperienceCard from "components/misc/UserCareerExperienceCard/UserCareerExperienceCard";

import { useEffect, useState } from "react";
import { SignIn, SignUp, useUser } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";

import { UserCareerExperience } from "services/datatypes";
import routes from "services/routes";
import api from "services/api";
import Swal from "sweetalert2";

function MyCareersView() {
	const { isSignedIn, user } = useUser();
	const [queryParameters] = useSearchParams();

	const [authType, setAuthType] = useState("signin");

	const [learnMoreUrl, setLearnMoreUrl] = useState("")
	const [searchOptionMinimised, setSearchOptionMinimised] = useState<
		boolean | null
	>(null);

	const [careerExperiencesLoading, setCareerExperiencesLoading] =
		useState(false);
	const [careerExperiences, setCareerExperiences] = useState<
		Array<UserCareerExperience>
	>([]);

	useEffect(() => {
		var newAuthType = queryParameters.get("authType");
		if (authType == "signin" || authType == "signup") {
			setAuthType(newAuthType || "signin");
		} else {
			setAuthType("signin");
		}
	}, [queryParameters]);

	useEffect(() => {
		if (isSignedIn == true) {
			loadUserInformation();
			loadCareerExperiences();
		}
	}, [isSignedIn]);

	function saveUserInformation() {
		api.postUserInformation(learnMoreUrl)
			.then(() => {
				Swal.fire({
					title: 'Saved',
					text: 'Your profile has been updated',
					icon: 'success',
				})
			})
			.catch(() => {})
	}

	function loadUserInformation() {
		api.getUserInformation(user?.id).then((res) => {
			setLearnMoreUrl(res.learn_more_url ?? "")
		})
	}

	function loadCareerExperiences(hideForm = false) {
		setCareerExperiencesLoading(true);
		api.getCurrentUserCareerExperiences().then((res) => {
			setCareerExperiences(res);
			setCareerExperiencesLoading(false);
		});
	}

	return (
		<div className="my-careers-view">
			<h1 className="section-title">Give Answers</h1>

			{isSignedIn == false && (
				<div className="my-careers-sign-in anim-appear">
					<div className="thank-you">
						Please sign in to give answers regarding your own career
					</div>
					{authType == "signin" && (
						<div className="anim-slide-appear">
							<SignIn signUpUrl={routes.myCareers.full + "?authType=signup"} />
						</div>
					)}
					{authType == "signup" && (
						<div className="anim-slide-appear">
							<SignUp signInUrl={routes.myCareers.full + "?authType=signin"} />
						</div>
					)}
				</div>
			)}
			{isSignedIn == true && (
				<>
					<div className="box personal-information-box anim-appear">
						<div className="box-header">
							<div className="title-icon"><BadgeIcon /></div>
							<div className="title">Your Profile</div>
						</div>

						<div className="box-body">
							<div className="label">
								<LinkIcon />
								View Profile
							</div>
							<div className="enter-url-container">
								<TextField
									placeholder="Enter LinkedIn URL (e.g. https://www.linkedin.com/in/ryanroslansky/)"
									value={learnMoreUrl}
									onChange={(e) => {
										setLearnMoreUrl(e.target.value);
									}}
								/>
							</div>
						</div>

						<div className="box-footer">
							<div className="button" onClick={saveUserInformation}>Save</div>
						</div>
					</div>

					<div
						className={
							"box box-minimisable anim-appear" +
							(searchOptionMinimised == true
								? " minimised"
								: searchOptionMinimised == false
									? " maximised"
									: "")
						}
					>
						<div
							className="box-header"
							onClick={() => {
								setSearchOptionMinimised(!searchOptionMinimised);
							}}
						>
							<div className="title-icon">
								<BusinessCenterIcon />
							</div>
							<div className="title">Your career</div>
							<div className="flex-filler"></div>
							<div className="minimise-toggle">
								<KeyboardArrowUpIcon fontSize="large" />
							</div>
						</div>
						<div className="box-minimise">
							<div className="box-body">
								<p className="explanation-text">
									"Career" below refers to the general category and "Career
									branch" to the specific category, e.g., if you are a
									Cardiologist, your "Career" would be Medical Doctor and your
									"Career branch" would be Cardiologist.
								</p>
								<UserCareerExperienceCard
									initialState="add"
									addCareerCallback={loadCareerExperiences}
								/>
							</div>
						</div>
					</div>

					{careerExperiencesLoading == true && (
						<>
							<div style={{ textAlign: "center", marginTop: "60px" }}>
								<Loader />
							</div>
						</>
					)}

					{careerExperiencesLoading == false && (
						<>
							{careerExperiences.length == 0 && (
								<div style={{ textAlign: "center", marginTop: "60px" }}>
									You have not added a career yet
								</div>
							)}

							{careerExperiences.map((careerExperience) => (
								<div key={careerExperience.id} className="experience">
									<UserCareerExperienceCard
										key={careerExperience.id}
										initialState="view"
										userCareerExperience={careerExperience}
									/>
								</div>
							))}
						</>
					)}
				</>
			)}
		</div>
	);
}

export default MyCareersView;
